<template>
  <div class="container px-0 h-100">
    PROPERTY
  </div>
</template>

<script>
export default {
  name: "CalculatorPropertyPageView"
};
</script>
